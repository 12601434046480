import { LensSimulationType } from '@/custom/menicon/models';
import { JourneyTypeName } from '@/models';

const linearInterpolation = (
  heightMap: (number | string)[][],
  x: number,
  y: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => {
  // Get 4 known points around the point and make bilinear interpolation
  const x1y1 = typeof heightMap[y1][x1] === 'number' ? (heightMap[y1][x1] as number) : 0;
  const x1y2 = typeof heightMap[y2][x1] === 'number' ? (heightMap[y2][x1] as number) : 0;
  const x2y1 = typeof heightMap[y1][x2] === 'number' ? (heightMap[y1][x2] as number) : 0;
  const x2y2 = typeof heightMap[y2][x2] === 'number' ? (heightMap[y2][x2] as number) : 0;
  // Get linear interpolation for (y, x1) and (y, x2)
  const xy1 = ((x2 - x) / (x2 - x1)) * x1y1 + ((x - x1) / (x2 - x1)) * x2y1;
  const xy2 = ((x2 - x) / (x2 - x1)) * x1y2 + ((x - x1) / (x2 - x1)) * x2y2;
  // here we only need a linear interpolation
  if (x1 === x2) {
    // Get linear interpolation for (x1, y)
    return ((y2 - y) / (y2 - y1)) * x1y1 + ((y - y1) / (y2 - y1)) * x1y2;
  } else if (y1 === y2) {
    return xy1;
  }
  // we need to interpolate on both axis (bilinear interpolation)
  return ((y2 - y) / (y2 - y1)) * xy1 + ((y - y1) / (y2 - y1)) * xy2;
};

export const interpolate = (heightMap: (number | string)[][], x: number, y: number) => {
  // the coordinates already exist
  if (Number.isInteger(x) && Number.isInteger(y)) {
    return heightMap[y][x];
  }
  // the coordinates do not exist on the height map
  let x1 = x;
  let x2 = x;
  let y1 = y;
  let y2 = y;
  if (!Number.isInteger(x)) {
    x1 = Math.trunc(x);
    x2 = x1 + 1;
  }
  if (!Number.isInteger(y)) {
    y1 = Math.trunc(y);
    y2 = y1 + 1;
  }
  return linearInterpolation(heightMap, x, y, x1, y1, x2, y2);
};

/**
 * Get the height map value from the canvas point coordinates.
 *
 * @param heightMap Height map of clearance values. It contains numbers and 'NaN' values.
 * @param canvasX Point x coordinate on the canvas
 * @param canvasY Point y coordinate on the canvas
 * @param canvasWidth Width of the canvas
 * @param canvasHeight Height of the canvas
 */
export const getPointValue = (
  heightMap: (number | string)[][],
  canvasX: number,
  canvasY: number,
  canvasWidth: number,
  canvasHeight: number
): number | string => {
  if (
    canvasX < canvasWidth &&
    canvasX >= 0 &&
    canvasY < canvasHeight &&
    canvasY >= 0 &&
    canvasWidth > 0 &&
    canvasHeight > 0 &&
    heightMap.length &&
    heightMap[0].length
  ) {
    // From x canvas coordinate, get x height map coordinate
    const hMWidth = heightMap[0].length; // the width of the height map
    const wRatio = hMWidth / canvasWidth; // width ratio between height map and canvas
    const newX = Math.trunc(canvasX * wRatio); // x coordinate on the height map

    // Get y coordinate
    const hMHeight = heightMap.length;
    const hRatio = hMHeight / canvasHeight;
    const newY = Math.trunc(canvasY * hRatio);

    // Now that we have x and y values, get the point value
    return interpolate(heightMap, newX, newY);
  }
  return 'NaN'; // if the point can't be found, return NaN
};

export const getLensSimulation = (lensSimulation: string, lensFamilyName: JourneyTypeName): LensSimulationType => {
  if (Object.values(LensSimulationType).includes(lensSimulation as LensSimulationType)) {
    return lensSimulation as LensSimulationType;
  } else if (lensFamilyName === JourneyTypeName.MENICON_BLOOM_DAY) {
    return LensSimulationType.soft;
  } else if (
    lensFamilyName === JourneyTypeName.MENICON_BLOOM_NIGHT ||
    lensFamilyName === JourneyTypeName.ACUVUE_ABILITI
  ) {
    return LensSimulationType.fluor;
  }

  return LensSimulationType.none;
};
